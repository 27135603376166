<template>
  <Layout>
    <template #main>
      <h2>Files</h2>
      <ul>
        <li v-for="file in files" :key="file._id">
          <router-link :to="'/file/' + file._id">{{ file.name }}</router-link>
        </li>
      </ul>
    </template>

    <template #related>
      <h2>Related Information</h2>
      <p>Additional file-related content, links, etc.</p>
    </template>
  </Layout>
</template>

<script>
import Layout from './Layout.vue';
import { ref, onMounted, inject } from 'vue';
import { useUserStore } from '../stores/userStore'; // Import the Pinia store

export default {
  components: { Layout },
  setup() {
    const files = ref([]);
    const apiUrl = inject('apiUrl'); // Inject the global apiUrl
    const userStore = useUserStore(); // Initialize Pinia user store

    onMounted(async () => {
      try {
        const response = await fetch(`${apiUrl}/api/files`, {
          credentials: 'include', // Include cookies for session management
        });
        if (response.ok) {
          const data = await response.json();
          files.value = data.files || data; // Adjust based on response structure
        } else {
          console.error('Error fetching files:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    });

    return {
      files,
    };
  },
};
</script>

<style scoped>
/* Add any relevant styles here */
</style>
