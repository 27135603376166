<!-- frontend/src/components/TaxReturnFilterWidget.vue -->
<template>
  <div class="tax-return-filter-widget">
    <h3>Tax Returns</h3>

    <!-- Debugging: Display companyId and Total Documents -->
    <!--div v-if="debug" class="debug">
      <strong>Current companyId:</strong> {{ companyId }}
      <strong>Total Documents:</strong> {{ totalDocuments }}
    </div-->

    <div v-if="companyId" class="tax-returns-table">
      <table class="common-table">
        <thead>
          <tr>
            <!-- Return Type Column with Sort and Filter -->
            <th @click="toggleSort('returnType')" tabindex="0" @keypress.enter="toggleSort('returnType')">
              Return Type
              <span v-if="sortOptions.sortBy === 'returnType'">
                {{ sortOptions.sortOrder === 'asc' ? '▲' : '▼' }}
              </span>
              <input
                type="text"
                v-model="filters.returnType"
                @input="debouncedApplyFilters"
                placeholder="Search Return Type"
                class="column-filter"
                aria-label="Search Return Type"
              />
            </th>

            <!-- Tax Year Column with Sort and Filter -->
            <th @click="toggleSort('taxYear')" tabindex="0" @keypress.enter="toggleSort('taxYear')">
              Tax Year
              <span v-if="sortOptions.sortBy === 'taxYear'">
                {{ sortOptions.sortOrder === 'asc' ? '▲' : '▼' }}
              </span>
              <input
                type="number"
                v-model="filters.taxYear"
                @input="debouncedApplyFilters"
                placeholder="Search Tax Year"
                class="column-filter"
                aria-label="Search Tax Year"
              />
            </th>

            <!-- Status Column with Sort and Filter -->
            <th @click="toggleSort('status')" tabindex="0" @keypress.enter="toggleSort('status')">
              Status
              <span v-if="sortOptions.sortBy === 'status'">
                {{ sortOptions.sortOrder === 'asc' ? '▲' : '▼' }}
              </span>
              <select v-model="filters.status" @change="applyFilters" class="column-filter" aria-label="Filter Status">
                <option value="">All</option>
                <option v-for="status in statuses" :key="status" :value="status">
                  {{ status }}
                </option>
              </select>
            </th>

            <!-- Account Column with Sort and Filter -->
            <th @click="toggleSort('account')" tabindex="0" @keypress.enter="toggleSort('account')">
              Account
              <span v-if="sortOptions.sortBy === 'account'">
                {{ sortOptions.sortOrder === 'asc' ? '▲' : '▼' }}
              </span>
              <select v-model="filters.accountId" @change="applyFilters" class="column-filter" aria-label="Filter Account">
                <option value="">All</option>
                <option v-for="account in accounts" :key="account._id" :value="account._id">
                  {{ account.name }}
                </option>
              </select>
            </th>

            <!-- Owner Column with Sort and Filter -->
            <th @click="toggleSort('owner')" tabindex="0" @keypress.enter="toggleSort('owner')">
              Owner
              <span v-if="sortOptions.sortBy === 'owner'">
                {{ sortOptions.sortOrder === 'asc' ? '▲' : '▼' }}
              </span>
              <select v-model="filters.ownerId" @change="applyFilters" class="column-filter" aria-label="Filter Owner">
                <option value="">All</option>
                <option v-for="owner in owners" :key="owner._id" :value="owner._id">
                  {{ owner.name }}
                </option>
              </select>
            </th>

            <!-- Due Date Column with Sort and Filter -->
            <th @click="toggleSort('dueDate')" tabindex="0" @keypress.enter="toggleSort('dueDate')">
              Due Date
              <span v-if="sortOptions.sortBy === 'dueDate'">
                {{ sortOptions.sortOrder === 'asc' ? '▲' : '▼' }}
              </span>
              <input
                type="date"
                v-model="filters.dueDate"
                @input="debouncedApplyFilters"
                placeholder="Search Due Date"
                class="column-filter"
                aria-label="Search Due Date"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="taxReturn in taxReturns"
            :key="taxReturn._id"
            @click="viewTaxReturn(taxReturn._id)"
            class="tax-return-row"
          >
            <td>{{ taxReturn.returnType }}</td>
            <td>{{ taxReturn.taxYear }}</td>
            <td>{{ taxReturn.status }}</td>
            <td>{{ taxReturn.account.name || 'Unknown Account' }}</td>
            <td>{{ taxReturn.owner.email || 'Unknown Owner' }}</td>
            <td>{{ formatDate(taxReturn.dueDate) }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Conditional "No Records" Message -->
      <div v-if="!loading && taxReturns.length === 0" class="no-results">
        No tax returns found for the selected criteria.
      </div>

      <!-- Pagination Controls -->
      <div class="pagination" v-if="totalPages > 1">
        <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
      </div>

      <!-- Total Records Found -->
      <div class="kpi">
        Total Records Found: {{ totalDocuments }}
      </div>

      <!-- Loading and Error Messages -->
      <div v-if="loading" class="loading">Loading tax returns...</div>
      <div v-if="error" class="error-message">{{ error }}</div>
    </div>

    <!-- Removed the "No Company Selected" Message -->
    <!--
    <div v-else class="no-company-selected">
      Please select a company to view tax returns.
    </div>
    -->
  </div>
</template>

<script>
import { computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useTaxReturnStore } from '../stores/taxReturnStore';
import { useUserStore } from '../stores/userStore';
import debounce from 'lodash.debounce';

export default {
  name: 'TaxReturnFilterWidget',
  setup() {
    const router = useRouter();
    const taxReturnStore = useTaxReturnStore();
    const userStore = useUserStore();

    const companyId = computed(() => userStore.companyId);
    const taxReturns = computed(() => taxReturnStore.taxReturns);
    const loading = computed(() => taxReturnStore.loading);
    const debug = true; 

    // Define totalDocuments as a computed property for reactivity
    const totalDocuments = computed(() => taxReturnStore.totalDocuments);

    // Watch to log totalDocuments changes
    watch(
      () => taxReturnStore.totalDocuments,
      (newVal) => {
        console.log(`Frontend watch: totalDocuments updated to ${newVal}`);
      }
    );

    const debouncedApplyFilters = debounce(() => {
      taxReturnStore.applyFilters();
    }, 300);

    const toggleSort = (column) => {
      console.log(`Toggling sort for column: ${column}`);
      taxReturnStore.toggleSort(column);
    };

    const applyFilters = () => {
      console.log('Applying filters:', taxReturnStore.filters);
      taxReturnStore.applyFilters();
    };

    const changePage = (newPage) => {
      console.log(`Changing page to: ${newPage}`);
      taxReturnStore.changePage(newPage);
    };

    const viewTaxReturn = (taxReturnId) => {
      console.log(`Viewing tax return ID: ${taxReturnId}`);
      router.push({ name: 'TaxReturnDetail', params: { id: taxReturnId } });
    };

    const formatDate = (date) => {
      if (!date) return '';
      return new Date(date).toLocaleDateString();
    };

    const getAccountName = (accountId) => {
      if (Array.isArray(taxReturnStore.accounts)) {
        const account = taxReturnStore.accounts.find((acc) => acc._id === accountId);
        return account ? account.name : 'Unknown Account';
      } else {
        console.error('accounts is not an array:', taxReturnStore.accounts);
        return 'Unknown Account';
      }
    };

    const getOwnerName = (ownerId) => {
      if (Array.isArray(taxReturnStore.owners)) {
        const owner = taxReturnStore.owners.find((owner) => owner._id === ownerId);
        return owner ? owner.name : 'Unknown Owner';
      } else {
        console.error('owners is not an array:', taxReturnStore.owners);
        return 'Unknown Owner';
      }
    };

    watch(
      companyId,
      (newCompanyId, oldCompanyId) => {
        if (newCompanyId && newCompanyId !== oldCompanyId) {
          console.log(`Company ID changed from ${oldCompanyId} to ${newCompanyId}. Re-initializing store.`);
          taxReturnStore.initialize();
        }
      }
    );

    onMounted(() => {
      if (companyId.value) {
        console.log('Component mounted. Initializing taxReturnStore.');
        taxReturnStore.initialize();
        taxReturns.value.forEach((taxReturn) => {
        console.log('TaxReturn:', taxReturn);
        console.log('TaxReturn.account:', taxReturn.account);
        console.log('TaxReturn.owner:', taxReturn.owner);
});

      } else {
        console.warn('No companyId found in the user store.');
      }
    });

    return {
      taxReturns,
      loading,
      error: taxReturnStore.error,
      filters: taxReturnStore.filters,
      sortOptions: taxReturnStore.sortOptions,
      statuses: taxReturnStore.statuses,
      accounts: taxReturnStore.accounts,
      owners: taxReturnStore.owners,
      totalPages: taxReturnStore.totalPages,
      currentPage: taxReturnStore.currentPage,
      totalDocuments, // Use the computed property
      toggleSort,
      applyFilters,
      changePage,
      viewTaxReturn,
      formatDate,
      getAccountName,
      getOwnerName,
      debouncedApplyFilters,
      companyId,
      debug,
    };
  },
};
</script>

<style scoped>
.tax-return-filter-widget {
  padding: 20px;
}

.tax-returns-table {
  margin-top: 20px;
}

/* Remove or comment out the existing table-specific styles */

table {
  /* These styles are now handled by the .common-table class */
}

thead th {
  /* These styles are now handled by the .common-table class */
}

td {
  /* These styles are now handled by the .common-table class */
}

.tax-return-row:hover {
  /* These styles are now handled by the .common-table class */
}

/* Remove or comment out any additional table-specific styles */

/* Retain other styles as needed */
.column-filter {
  margin-top: 5px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  padding: 8px 12px;
  margin: 0 10px;
  cursor: pointer;
}

.kpi {
  margin-top: 20px;
  font-weight: bold;
  text-align: right;
}

.loading,
.error-message,
.no-results {
  text-align: center;
  margin: 20px 0;
}

.error-message {
  color: red;
}

/* Accessibility Focus Styles */
th:focus {
  outline: 2px solid #007BFF;
}

.debug {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
}
</style>
