// frontend/src/stores/taxReturnStore.js

import { defineStore } from 'pinia';
import { ref, computed, watch, onMounted } from 'vue';
import { useUserStore } from './userStore';

export const useTaxReturnStore = defineStore('taxReturnStore', () => {
  const userStore = useUserStore();

  const taxReturns = ref([]);
  const loading = ref(false);
  const error = ref(null);
  const filters = ref({
    returnType: '',
    taxYear: '',
    status: 'Pending', // Default to "Pending"
    accountId: '',
    ownerId: '',
    dueDate: '',
  });
  const sortOptions = ref({
    sortBy: 'dueDate',
    sortOrder: 'asc',
  });
  const statuses = ['Pending', 'Filed', 'Overdue'];
  const accounts = ref([]);
  const owners = ref([]);
  const totalDocuments = ref(0);
  const totalPages = ref(1);
  const currentPage = ref(1);

  const apiUrl = import.meta.env.VITE_API_URL || 'https://localhost:3000';

  const companyId = computed(() => userStore.companyId);

  const fetchTaxReturns = async () => {
    if (!companyId.value) {
      console.error('Company ID is not provided. Cannot fetch tax returns.');
      taxReturns.value = [];
      totalDocuments.value = 0;
      totalPages.value = 1;
      currentPage.value = 1;
      return;
    }

    loading.value = true;
    error.value = null;
    try {
      const queryParams = new URLSearchParams({
        companyId: companyId.value,
        ...filters.value,
        sortBy: sortOptions.value.sortBy,
        sortOrder: sortOptions.value.sortOrder,
        page: currentPage.value,
        limit: 10,
      }).toString();

      console.log('Fetching tax returns with query:', queryParams);

      const response = await fetch(`${apiUrl}/api/taxreturns/filter?${queryParams}`, {
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched tax returns data:', data);

        if (Array.isArray(data.taxReturns)) {
          taxReturns.value = data.taxReturns;
          console.log('Assigned taxReturns:', taxReturns.value);
        } else {
          console.error('taxReturns is not an array:', data.taxReturns);
          taxReturns.value = [];
        }

        // Assign totalDocuments correctly
        totalDocuments.value = data.totalDocuments !== undefined ? data.totalDocuments : (data.total || taxReturns.value.length);
        console.log('Total Documents:', totalDocuments.value);

        totalPages.value = data.totalPages || 1;
        currentPage.value = data.currentPage || 1;
      } else {
        const errorText = await response.text();
        console.error('Error fetching tax returns:', response.statusText, errorText);
        error.value = 'Failed to load tax returns.';
        taxReturns.value = [];
        totalDocuments.value = 0;
        totalPages.value = 1;
        currentPage.value = 1;
      }
    } catch (err) {
      console.error('Error fetching tax returns:', err);
      error.value = 'An error occurred while fetching tax returns.';
      taxReturns.value = [];
      totalDocuments.value = 0;
      totalPages.value = 1;
      currentPage.value = 1;
    } finally {
      loading.value = false;
      console.log('Loading state:', loading.value);
    }
  };

  const applyFilters = () => {
    currentPage.value = 1;
    console.log('Applying filters:', filters.value);
    fetchTaxReturns();
  };

  const resetFilters = () => {
    filters.value = {
      returnType: '',
      taxYear: '',
      status: 'Pending',
      accountId: '',
      ownerId: '',
      dueDate: '',
    };
    sortOptions.value = {
      sortBy: 'dueDate',
      sortOrder: 'asc',
    };
    currentPage.value = 1;
    fetchTaxReturns();
  };

  const toggleSort = (column) => {
    if (sortOptions.value.sortBy === column) {
      sortOptions.value.sortOrder = sortOptions.value.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      sortOptions.value.sortBy = column;
      sortOptions.value.sortOrder = 'asc';
    }
    fetchTaxReturns();
  };

  const changePage = (newPage) => {
    if (newPage < 1 || newPage > totalPages.value) return;
    currentPage.value = newPage;
    fetchTaxReturns();
  };

  watch(
    companyId,
    (newCompanyId, oldCompanyId) => {
      if (newCompanyId && newCompanyId !== oldCompanyId) {
        console.log(`Company ID changed from ${oldCompanyId} to ${newCompanyId}. Re-initializing store.`);
        initialize();
      }
    }
  );

  const initialize = () => {
    fetchTaxReturns();
  };

  onMounted(() => {
    if (companyId.value) {
      console.log('Store initialized. Fetching tax returns.');
      fetchTaxReturns();
    }
  });

  return {
    taxReturns,
    loading,
    error,
    filters,
    sortOptions,
    statuses,
    accounts,
    owners,
    totalPages,
    currentPage,
    totalDocuments,
    toggleSort,
    applyFilters,
    resetFilters,
    changePage,
    initialize,
  };
});
