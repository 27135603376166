// main.js
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { useUserStore } from './stores/userStore'; // Updated import

console.log("main.js is loaded");

const SESSION_TIMEOUT = 15 * 60 * 1000; // 15 minutes

const app = createApp(App);
const pinia = createPinia();

// Set the apiUrl globally in Vue
app.config.globalProperties.$apiUrl =
  import.meta.env.VITE_API_URL || 'http://localhost:3000';
app.provide('apiUrl', app.config.globalProperties.$apiUrl); // Provide globally

app.use(pinia); // Use Pinia before other plugins
app.use(router);
app.mount('#app');

// Initialize Pinia stores after the app is mounted
const userStore = useUserStore();

// Session timeout logic
let sessionTimeout;

const resetSessionTimeout = () => {
  if (sessionTimeout) {
    clearTimeout(sessionTimeout);
  }
  sessionTimeout = setTimeout(() => {
    // Session expired, log out the user
    logout();
  }, SESSION_TIMEOUT);
};

// Add event listeners to reset session timeout on user activity
document.addEventListener('mousemove', resetSessionTimeout);
document.addEventListener('click', resetSessionTimeout);
document.addEventListener('keypress', resetSessionTimeout);

// Function to log out the user
const logout = async () => {
  try {
    // Clear user data in userStore
    userStore.clearUser();

    // Sign out from the backend session
    await fetch(`${app.config.globalProperties.$apiUrl}/api/auth/logout`, {
      method: 'POST',
      credentials: 'include',
    });

    console.log("User session timed out and signed out");
    router.push('/signin');
  } catch (error) {
    console.error("Sign out error:", error);
  }
};

// Fetch session data on app initialization
(async () => {
  try {
    const response = await fetch(`${app.config.globalProperties.$apiUrl}/api/auth/session`, {
      credentials: 'include',
    });
    if (response.ok) {
      const data = await response.json();
      userStore.setUser(data.user);
      console.log("User data loaded on app init:", userStore.user);
      resetSessionTimeout(); // Start the session timeout
    } else {
      console.log("No active session found on app init");
      router.push('/signin');
    }
  } catch (error) {
    console.error('Error fetching session data on app init:', error);
    router.push('/signin');
  }
})();
