  <template>
    <div class="tax-return-detail">
      <h2>{{ isNew ? 'Create New Tax Return' : (editMode ? 'Edit Tax Return' : 'Tax Return Details') }}</h2>

      <!-- Form in Edit Mode or Create Mode -->
      <form v-if="editMode || isNew" @submit.prevent="saveTaxReturn">
        <!-- Two-column grid layout -->
        <div class="form-grid">
          <!-- Left Column -->
          <div class="form-column">
            <!-- Account (readonly) -->
            <label for="account">Account:</label>
            <input type="text" id="account" v-model="accountName" readonly />

            <!-- Return Type -->
            <label for="returnType">Return Type:</label>
            <select id="returnType" v-model="taxReturn.returnType" required>
              <option disabled value="">Select a Return Type</option>
              <option value="GST/HST">GST/HST</option>
              <option value="T2">T2</option>
              <option value="T4">T4</option>
              <option value="T5">T5</option>
            </select>

            <!-- Tax Year -->
            <label for="taxYear">Tax Year:</label>
            <input type="number" id="taxYear" v-model="taxReturn.taxYear" required />

            <!-- Period Start Date -->
            <label for="periodStartDate">Period Start Date:</label>
            <input type="date" id="periodStartDate" v-model="taxReturn.periodStartDate" />

            <!-- Period End Date -->
            <label for="periodEndDate">Period End Date:</label>
            <input type="date" id="periodEndDate" v-model="taxReturn.periodEndDate" />
          </div> <!-- Closing tag for the left form-column -->

          <!-- Right Column -->
          <div class="form-column">
            <!-- Status -->
            <label for="status">Status:</label>
            <select id="status" v-model="taxReturn.status">
              <option value="Pending">Pending</option>
              <option value="Filed">Filed</option>
              <option value="Overdue">Overdue</option>
            </select>

            <!-- Due Date -->
            <label for="dueDate">Due Date:</label>
            <input type="date" id="dueDate" v-model="taxReturn.dueDate" required />

            <!-- Filing Date -->
            <label for="filingDate">Filing Date (optional):</label>
            <input type="date" id="filingDate" v-model="taxReturn.filingDate" />

            <!-- Tax Amount -->
            <label for="taxAmount">Tax Amount (optional):</label>
            <input type="number" id="taxAmount" v-model="taxReturn.taxAmount" step="0.01" />

            <!-- Owner Selection: Visible Only to System Admins and Managers -->
            <div v-if="userProfile === 'System Admin' || userProfile === 'Manager'">
              <label for="owner">Owner:</label>
              <select id="owner" v-model="taxReturn.owner" required>
                <option disabled value="">Select an Owner</option>
                <option v-for="user in companyUsers" :key="user._id" :value="user._id">
                  {{ user.email }} - {{ user.profile }}
                </option>
              </select>
            </div>
          </div> <!-- Closing tag for the right form-column -->
        </div> <!-- Closing tag for the form-grid -->

        <!-- Payment Section (visible only when editing an existing record) -->
        <div v-if="!isNew" class="payment-section">
          <h3>Payment</h3>
          <!-- Payment Needed -->
          <div class="form-group">
            <label for="paymentNeeded">Payment Needed:</label>
            <input
              type="checkbox"
              id="paymentNeeded"
              v-model="taxReturn.paymentNeeded"
            />
          </div>

          <!-- Payment Received -->
          <div class="form-group">
            <label for="paymentReceived">Payment Received:</label>
            <input
              type="checkbox"
              id="paymentReceived"
              v-model="taxReturn.paymentReceived"
              :disabled="!taxReturn.paymentNeeded"
            />
          </div>

          <!-- Payment Date -->
          <div class="form-group">
            <label for="paymentDate">Payment Date:</label>
            <input
              type="date"
              id="paymentDate"
              v-model="taxReturn.paymentDate"
              :disabled="!taxReturn.paymentReceived"
            />
          </div>

          <!-- Payment Mode -->
          <div class="form-group">
            <label for="paymentMode">Payment Mode:</label>
            <select
              id="paymentMode"
              v-model="taxReturn.paymentMode"
              :disabled="!taxReturn.paymentReceived"
            >
              <option disabled value="">Select Payment Mode</option>
              <option value="Cash">Cash</option>
              <option value="Cheque">Cheque</option>
              <option value="e-Transfer">e-Transfer</option>
            </select>
          </div>
        </div>

        <!-- Form Actions -->
        <div class="form-actions">
          <button type="submit">{{ isNew ? 'Create Tax Return' : 'Save Changes' }}</button>
          <button type="button" @click="cancelEdit">Cancel</button>
        </div>
      </form>

      <!-- View Mode -->
      <div v-else>
        <!-- Two-column grid layout -->
        <div class="view-grid">
          <!-- Left Column -->
          <div class="view-column">
            <div class="detail-item">
              <strong>Account:</strong> <span>{{ accountName }}</span>
            </div>
            <div class="detail-item">
              <strong>Return Type:</strong> <span>{{ taxReturn.returnType }}</span>
            </div>
            <div class="detail-item">
              <strong>Tax Year:</strong> <span>{{ taxReturn.taxYear }}</span>
            </div>
            <div class="detail-item">
              <strong>Period:</strong> <span>{{ formattedPeriod }}</span>
            </div>
            <div class="detail-item">
              <strong>Status:</strong> <span>{{ taxReturn.status }}</span>
            </div>
          </div>

          <!-- Right Column -->
          <div class="view-column">
            <div class="detail-item">
              <strong>Due Date:</strong> <span>{{ formattedDueDate }}</span>
            </div>
            <div class="detail-item" v-if="taxReturn.filingDate">
              <strong>Filing Date:</strong> <span>{{ formattedFilingDate }}</span>
            </div>
            <div class="detail-item" v-if="taxReturn.taxAmount">
              <strong>Tax Amount:</strong> <span>{{ taxReturn.taxAmount }}</span>
            </div>
            <!-- Owner Selection: Visible Only to System Admins and Managers in Edit Mode -->
            <div v-if="(userProfile === 'System Admin' || userProfile === 'Manager') && (editMode || isNew)">
              <label for="owner">Owner:</label>
              <select id="owner" v-model="taxReturn.owner" required>
                <option disabled value="">Select an Owner</option>
                <option v-for="user in companyUsers" :key="user._id" :value="user._id">
                  {{ user.email }} - {{ user.profile }}
                </option>
              </select>
            </div>

            <!-- Display owner name in view mode -->
            <div v-else>
              <div class="detail-item">
                <strong>Owner:</strong> <span>{{ ownerName }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Payment Information -->
        <div class="payment-info">
          <h3>Payment Information</h3>
          <div class="detail-item">
            <strong>Payment Needed:</strong>
            <span>{{ taxReturn.paymentNeeded ? 'Yes' : 'No' }}</span>
          </div>
          <div class="detail-item" v-if="taxReturn.paymentNeeded">
            <strong>Payment Received:</strong>
            <span>{{ taxReturn.paymentReceived ? 'Yes' : 'No' }}</span>
          </div>
          <div class="detail-item" v-if="taxReturn.paymentReceived">
            <strong>Payment Date:</strong>
            <span>{{ formattedPaymentDate }}</span>
          </div>
          <div class="detail-item" v-if="taxReturn.paymentReceived">
            <strong>Payment Mode:</strong>
            <span>{{ taxReturn.paymentMode }}</span>
          </div>
        </div>

        <!-- Actions -->
        <div class="view-actions">
          <button @click="goBack">Back to Account</button>
          <button @click="editTaxReturn">Edit Tax Return</button>
        </div>
      </div>
    </div> <!-- Closing tag for .tax-return-detail -->
  </template>


  <script>
  import { ref, computed, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useUserStore } from '../stores/userStore';
  //import { isAdminOrManager} from '../../../backend/middleware/isAdminOrManager';

  export default {
    props: {
      accountId: {
        type: String,
        required: true,
      },
      editMode: {
        type: Boolean,
        required: true,
      },
    },
    setup(props) {
      const route = useRoute();
      const router = useRouter();
      const userStore = useUserStore();
      const userProfile = userStore.userProfile;
      const taxReturn = ref({});
      const loading = ref(true);
      const companyUsers = ref([]);
      const isNew = route.query.new === 'true';
      const apiUrl = import.meta.env.VITE_API_URL;
      const accountName = ref('');
      const ownerName = ref('');
      const editMode = ref(route.query.editMode === 'true'); 

      const fetchTaxReturnDetails = async () => {
        console.log('fetchTaxReturnDetails: Starting to fetch tax return details');
        if (!isNew) {
          try {
            const response = await fetch(`${apiUrl}/api/taxreturns/${route.params.id}`, {
              credentials: 'include',
            });
            if (response.ok) {
              taxReturn.value = await response.json();
              console.log('TaxReturnDetail: Tax return: ', taxReturn.value);
              console.log('TaxReturnDetail: Tax return owner: ', taxReturn.value.owner);
              console.log('TaxReturnDetail: Tax return owner.email: ', taxReturn.value.owner?.email);

              // Use populated account and owner names
              accountName.value = taxReturn.value.account?.name || 'N/A';
              ownerName.value = taxReturn.value.owner?.email || 'N/A';
            } else {
              console.error('Failed to fetch tax return details:', response.statusText);
            }
          } catch (err) {
            console.error('An error occurred while fetching the tax return details:', err);
          } finally {
            loading.value = false;
          }
        } else {
          // Initialize an empty tax return for new entries
          taxReturn.value = {
            account: props.accountId,
            // ... other fields ...
            owner: '', // Owner will be set by the user
          };
          await fetchAccountName(props.accountId);
          loading.value = false;
        }
      };

      const fetchAccountName = async (accountId) => {
        try {
          const response = await fetch(`${apiUrl}/api/accounts/${accountId}`, {
            credentials: 'include',
          });
          if (response.ok) {
            const accountData = await response.json();
            accountName.value = accountData.name || '';
          } else {
            console.error('Failed to fetch account details:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching account details:', error);
        }
      };

      const fetchOwnerName = async (ownerId) => {
        try {
          const response = await fetch(`${apiUrl}/api/users/${ownerId}`, {
            credentials: 'include',
          });
          if (response.ok) {
            const ownerData = await response.json();
            ownerName.value = ownerData.email || '';
          } else {
            console.error('Failed to fetch owner details:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching owner details:', error);
        }
      };

      const fetchCompanyUsers = async () => {
        try {
          const companyId = userStore.userProfile === 'System Admin' ? userStore.companyId : userStore.user.companyId;
          if (!companyId) {
            console.error('Company ID is missing. Cannot fetch company users.');
            return;
          }

          // **Updated Endpoint:** Adjusted to use the new route for Employees and System Admins
          const response = await fetch(`${apiUrl}/api/admin/company/${companyId}/users`, { credentials: 'include' });
          if (response.ok) {
            companyUsers.value = (await response.json()).users;
            if (!taxReturn.value.owner && companyUsers.value.length && userStore.userProfile === 'System Admin') {
              taxReturn.value.owner = companyUsers.value.find((user) => user.profile === 'Manager')?._id || '';
            }
          } else {
            console.error('Error fetching company users:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching company users:', error);
        }
      };

      onMounted(() => {
        fetchTaxReturnDetails();
        // Fetch company users only if the user is a System Admin
        if ((userStore.userProfile === 'System Admin' || userProfile === 'Manager')  && (editMode.value || isNew)) {
          fetchCompanyUsers();
        }
      });

      const formattedPeriod = computed(() => {
        if (taxReturn.value.periodStartDate && taxReturn.value.periodEndDate) {
          const start = new Date(taxReturn.value.periodStartDate).toLocaleDateString();
          const end = new Date(taxReturn.value.periodEndDate).toLocaleDateString();
          return `${start} - ${end}`;
        }
        return 'N/A';
      });

      const formattedDueDate = computed(() => {
        return taxReturn.value?.dueDate ? new Date(taxReturn.value.dueDate).toLocaleDateString() : 'N/A';
      });

      const formattedFilingDate = computed(() => {
        return taxReturn.value?.filingDate ? new Date(taxReturn.value.filingDate).toLocaleDateString() : 'N/A';
      });

      const formattedPaymentDate = computed(() => {
        return taxReturn.value?.paymentDate ? new Date(taxReturn.value.paymentDate).toLocaleDateString() : 'N/A';
      });

      const goBack = () => {
        router.push(`/accounts/${props.accountId}`);
      };

      const saveTaxReturn = async () => {
        // Validation logic
        if (taxReturn.value.paymentReceived && !taxReturn.value.paymentNeeded) {
          alert('Payment cannot be received if payment is not needed.');
          return;
        }

        const method = isNew ? 'POST' : 'PUT';
        const url = isNew
          ? `${apiUrl}/api/taxreturns`
          : `${apiUrl}/api/taxreturns/${route.params.id}`;
        try {
          console.log('Submitting TaxReturn data:', taxReturn.value);
          const response = await fetch(url, {
            method: method,
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(taxReturn.value),
          });
          if (response.ok) {
            console.log('Tax return saved successfully');
            if (isNew) {
              router.push(`/accounts/${props.accountId}`);
            } else {
              router.push({ name: 'TaxReturnDetail', params: { id: route.params.id }, query: { editMode: false } });
            }
          } else {
            const errorData = await response.json();
            console.error('Error saving tax return:', errorData.message);
            // Display error message to the user
            alert(`Error: ${errorData.message}`);
          }
        } catch (error) {
          console.error('Failed to save tax return:', error);
          alert('Failed to save tax return.');
        }
      };

      const cancelEdit = () => {
        if (isNew) {
          goBack();
        } else {
          router.push({ name: 'TaxReturnDetail', params: { id: route.params.id }, query: { editMode: false } });
        }
      };

      const editTaxReturn = () => {
        router.push({ name: 'TaxReturnDetail', params: { id: route.params.id }, query: { editMode: true } });
      };

      return {
        taxReturn,
        loading,
        companyUsers,
        userProfile,
        formattedPeriod,
        formattedDueDate,
        formattedFilingDate,
        formattedPaymentDate,
        accountName,
        ownerName,
        goBack,
        saveTaxReturn,
        cancelEdit,
        editTaxReturn,
        isNew,
        user: userStore, // Ensure you have user data available
        errorMessage: ref(''), // Initialize errorMessage
      };
    },
  };
  </script>

  <style scoped>
  .tax-return-detail {
    padding: 20px;
  }

  .form-grid,
  .view-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .form-column,
  .view-column {
    display: flex;
    flex-direction: column;
  }

  .detail-item {
    margin-bottom: 10px;
  }

  form {
    /* Additional form styles if needed */
  }

  .form-actions,
  .view-actions {
    margin-top: 20px;
  }

  button {
    margin-right: 10px;
  }

  .payment-section {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .payment-section h3 {
    margin-bottom: 15px;
  }

  .payment-info h3 {
    margin-top: 20px;
  }

  input[disabled],
  select[disabled] {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  .error {
    color: red;
    margin-top: 10px;
  }
  </style>
