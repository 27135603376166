<template>
  <div>
    <div v-if="companySelected">
      <TaxReturnFilterWidget />
    </div>
    <div v-else>
      <p>Please select a company to view tax returns.</p>
      <!-- You can include a component or link here to select a company -->
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useUserStore } from '../stores/userStore'; // Adjust the path as necessary
import TaxReturnFilterWidget from '../components/TaxReturnFilterWidget.vue';

export default {
  name: 'Home',
  components: {
    TaxReturnFilterWidget,
  },
  setup() {
    const userStore = useUserStore();

    // Computed property to check if a company is selected
    const companySelected = computed(() => {
      return userStore.companyId != null && userStore.companyId !== '';
    });

    return {
      companySelected,
    };
  },
};
</script>

<style scoped>
/* Add any styles if needed */
</style>
