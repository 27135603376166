<template>
  <div>
    <h1>Company List</h1>
    <ul>
      <li v-for="company in companies" :key="company._id">{{ company.companyName }}</li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import { useUserStore } from '../stores/userStore'; // Import the Pinia store

export default {
  name: 'CompanyList',
  setup() {
    const companies = ref([]);
    const apiUrl = inject('apiUrl'); // Inject the global apiUrl
    const userStore = useUserStore(); // Initialize Pinia user store

    onMounted(async () => {
      console.log("Mounted CompanyList component");

      try {
        const response = await fetch(`${apiUrl}/api/companies`, {
          credentials: 'include', // Include cookies for session management
        });
        console.log("Received response from backend:", response.status); // Debug: Log the response status

        if (!response.ok) {
          console.error("Error fetching companies, status:", response.status);
          return;
        }

        const data = await response.json();
        companies.value = data.companies || data; // Adjust based on response structure
        console.log("Companies data:", companies.value); // Debug: Log the fetched data

      } catch (error) {
        console.error("Error during fetch:", error); // Debug: Log any fetch errors
      }
    });

    return {
      companies,
    };
  },
};
</script>
