<template>
  <Layout>
    <template #main>
      <h2>{{ isEditing ? 'Edit Company' : 'Create New Company' }}</h2>
      <form @submit.prevent="handleSubmit">
        <div>
          <label for="companyName">Company Name:</label>
          <input type="text" id="companyName" v-model="company.companyName" required />
        </div>

        <div>
          <label for="address">Address:</label>
          <input type="text" id="address" v-model="company.address" />
        </div>

        <div>
          <label for="industry">Industry:</label>
          <input type="text" id="industry" v-model="company.industry" />
        </div>

        <button type="submit">{{ isEditing ? 'Update Company' : 'Create Company' }}</button>
      </form>
    </template>

    <template #related>
      <h2>Related Information</h2>
      <p>Additional company-related content, links, etc.</p>
    </template>
  </Layout>
</template>

<script>
import Layout from './Layout.vue';
import { ref, onMounted, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '../stores/userStore'; // Import the Pinia store

export default {
  components: { Layout },
  setup() {
    const company = ref({
      companyName: '',
      address: '',
      industry: '',
    });

    const isEditing = ref(false);
    const router = useRouter();
    const route = useRoute();
    const apiUrl = inject('apiUrl'); // Inject the global apiUrl
    const userStore = useUserStore(); // Initialize Pinia user store

    onMounted(() => {
      if (route.params.id) {
        isEditing.value = true;
        // Fetch the company details from the backend using the ID
        fetch(`${apiUrl}/api/companies/${route.params.id}`, {
          credentials: 'include', // Include cookies for session management
        })
          .then((response) => response.json())
          .then((data) => {
            company.value = data;
          })
          .catch((error) => {
            console.error('Error fetching company details:', error);
          });
      }
    });

    const handleSubmit = async () => {
      try {
        const method = isEditing.value ? 'PUT' : 'POST';
        const url = isEditing.value
          ? `${apiUrl}/api/companies/${route.params.id}`
          : `${apiUrl}/api/companies`;

        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include cookies for session management
          body: JSON.stringify(company.value),
        });

        if (response.ok) {
          console.log('Company saved successfully');
          router.push('/companies'); // Redirect after form submission
        } else {
          console.error('Error saving company:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to save company:', error);
      }
    };

    return {
      company,
      isEditing,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #36a673;
}
</style>
