// frontend/src/stores/accountStore.js
import { defineStore } from 'pinia';
import debounce from 'lodash.debounce';

export const useAccountStore = defineStore('account', {
  state: () => ({
    accounts: [],
    loading: false,
    error: null, // Added error state
    currentPage: 1,
    totalPages: 1,
    pageSize: 20,
    filters: {
      name: '',
      natureOfBusiness: '',
      gstHstNumber: '',
      address: '',
      phoneNumber: '',
      activeStatus: '',
    },
    showSearch: false,
  }),
  actions: {
    /**
     * Toggles the visibility of the search form
     */
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    /**
     * Sets new filters and fetches accounts (debounced)
     * @param {Object} newFilters
     */
    setFilters(newFilters) {
      this.filters = { ...newFilters };
      this.currentPage = 1;
      this.fetchAccountsDebounced();
    },

    /**
     * Resets all filters and fetches accounts immediately
     */
    resetFilters() {
      this.filters = {
        name: '',
        natureOfBusiness: '',
        gstHstNumber: '',
        address: '',
        phoneNumber: '',
        activeStatus: '',
      };
      this.currentPage = 1;
      this.fetchAccounts(); // Immediate fetch
    },

    /**
     * Changes the current page and fetches accounts immediately
     * @param {Number} newPage
     */
    changePage(newPage) {
      if (newPage >= 1 && newPage <= this.totalPages) {
        this.currentPage = newPage;
        this.fetchAccounts(); // Immediate fetch
      }
    },

    /**
     * Fetches accounts from the backend with current filters and pagination
     */
    async fetchAccounts() {
      this.loading = true;
      this.error = null; // Reset error state
      const queryParams = new URLSearchParams();

      // Append filters to query parameters
      Object.entries(this.filters).forEach(([key, value]) => {
        if (value) {
          queryParams.append(key, value);
        }
      });

      // Append pagination parameters
      queryParams.append('page', this.currentPage);
      queryParams.append('limit', this.pageSize);

      try {
        const response = await fetch(`/api/accounts?${queryParams.toString()}`, {
          credentials: 'include', // Include cookies if authentication is session-based
        });

        if (response.ok) {
          const result = await response.json();
          this.accounts = result.data;
          this.totalPages = result.meta.totalPages;
        } else {
          const errorData = await response.json();
          this.error = errorData.message || 'Failed to fetch accounts.';
          this.accounts = [];
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
        this.error = 'An error occurred while fetching accounts.';
        this.accounts = [];
      } finally {
        this.loading = false;
      }
    },

    /**
     * Debounced version of fetchAccounts
     */
    fetchAccountsDebounced: debounce(async function () {
      await this.fetchAccounts();
    }, 300),

    /**
     * Deletes an account by its ID
     * @param {String} accountId
     */
    async deleteAccount(accountId) {
      try {
        const response = await fetch(`/api/accounts/${accountId}`, {
          method: 'DELETE',
          credentials: 'include',
        });

        if (response.ok) {
          // Optionally, you can dispatch a notification here
          this.fetchAccounts(); // Immediate fetch to refresh the list
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to delete account.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        this.error = error.message;
      }
    },
  },
});
