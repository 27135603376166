<!-- frontend/components/SignIn.vue -->
<template>
  <div class="signin-container">
    <h2>Sign In</h2>
    <form @submit.prevent="signIn">
      <div class="form-group">
        <label for="email">Email:</label>
        <input
          type="email"
          id="email"
          v-model="email"
          placeholder="Email"
          required
          aria-label="Email"
        />
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input
          type="password"
          id="password"
          v-model="password"
          placeholder="Password"
          required
          aria-label="Password"
        />
      </div>
      <button type="submit" :disabled="loading">
        <span v-if="loading">Signing In...</span>
        <span v-else>Sign In</span>
      </button>
    </form>

    <!-- Display Error Message -->
    <div v-if="error" class="error-message" role="alert">
      {{ error }}
    </div>

    <!-- Display Inactive Account Message -->
    <div v-if="inactiveMessage" class="inactive-message" role="alert">
      {{ inactiveMessage }}
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { useUserStore } from '../stores/userStore'; // Updated import

export default {
  name: 'SignIn',
  setup() {
    const email = ref('');
    const password = ref('');
    const error = ref(null);
    const inactiveMessage = ref(null);
    const loading = ref(false);

    const apiUrl = inject('apiUrl'); // Inject the global apiUrl
    const router = useRouter();
    const userStore = useUserStore(); // Initialize Pinia user store

    const signIn = async () => {
      // Reset messages and set loading state
      error.value = null;
      inactiveMessage.value = null;
      loading.value = true;

      try {
        // Firebase sign-in
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email.value,
          password.value
        );
        const user = userCredential.user;

        // Get Firebase ID token
        const idToken = await user.getIdToken();

        // Backend login with ID token
        const response = await fetch(`${apiUrl}/api/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include cookies for session management
          body: JSON.stringify({ idToken }),
        });

        const data = await response.json();

        if (response.ok) {
          if (data.message) {
            // Account created but inactive
            inactiveMessage.value = data.message;
            // Optionally, you might want to clear the form or take other actions
          } else {
            // Successful login
            userStore.setUser({
              email: user.email,
              profile: data.profile, // Ensure profile is returned by the backend
              uid: data.uid, // Ensure uid is returned by the backend
              companyId: data.companyId || null, // Include companyId if available
            });

            // Redirect to the home page after login
            router.push('/');
          }
        } else {
          // Handle backend-specific errors
          const message = data.message || 'Login failed. Please try again.';
          if (response.status === 403) {
            inactiveMessage.value = message; // Account inactive
          } else {
            error.value = message;
          }
        }
      } catch (err) {
        // Handle Firebase errors (e.g., wrong password, user not found)
        if (err.code === 'auth/user-not-found') {
          error.value = 'No user found with this email.';
        } else if (err.code === 'auth/wrong-password') {
          error.value = 'Incorrect password. Please try again.';
        } else {
          error.value = err.message || 'Login failed. Please try again.';
        }
      } finally {
        // Clear password field and reset loading state
        password.value = '';
        loading.value = false;
      }
    };

    return {
      email,
      password,
      error,
      inactiveMessage,
      signIn,
      loading,
    };
  },
};
</script>

<style scoped>
.signin-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input[type='email'],
input[type='password'] {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 10px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

button:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #36a673;
}

.error-message {
  color: #d9534f;
  margin-top: 10px;
}

.inactive-message {
  color: #f0ad4e;
  margin-top: 10px;
}
</style>
