// stores/userStore.js
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null, // Holds user info including companyId and profile
    // New state properties for company users
    companyUsers: [],
    loadingCompanyUsers: false,
    companyUsersError: null,
  }),
  actions: {
    // Existing actions
    setUser(userData) {
      this.user = userData;
    },
    updateCompanyId(companyId) {
      if (this.user) {
        this.user.companyId = companyId;
      }
    },
    clearUser() {
      this.user = null;
    },
    // New actions for company users management
    async fetchCompanyUsers() {
      this.loadingCompanyUsers = true;
      this.companyUsersError = null;
      try {
        const companyId = this.companyId;
        if (!companyId) {
          throw new Error('Company ID is missing. Cannot fetch company users.');
        }
        const response = await fetch(`/api/companies/${companyId}/users`, {
          credentials: 'include',
        });
        if (response.ok) {
          this.companyUsers = await response.json();
        } else {
          const errorData = await response.json();
          this.companyUsersError = errorData.message || 'Failed to fetch company users.';
        }
      } catch (error) {
        console.error('Error fetching company users:', error);
        this.companyUsersError = 'An error occurred while fetching company users.';
      } finally {
        this.loadingCompanyUsers = false;
      }
    },
    // Additional actions for managing company users can be added here
    // For example, addCompanyUser, updateCompanyUser, deleteCompanyUser
    // These methods should be prefixed appropriately to avoid conflicts
  },
  getters: {
    // Existing getters
    companyId: (state) => state.user?.companyId || null,
    userProfile: (state) => state.user?.profile || '',
    isAuthenticated: (state) => !!state.user,
    // New getters for company users
    getCompanyUsers: (state) => state.companyUsers,
    isCompanyUsersLoading: (state) => state.loadingCompanyUsers,
    companyUsersError: (state) => state.companyUsersError,
  },
});
