<template>
  <div>
    <h2>Admin Dashboard</h2>
    <p>Assign a company and activate inactive users.</p>
    
    <table>
      <thead>
        <tr>
          <th>Email</th>
          <th>Profile</th>
          <th>Company</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in inactiveUsers" :key="user._id">
          <td>{{ user.email }}</td>
          <td>{{ user.profile }}</td>
          <td>
            <select v-model="selectedCompany[user._id]">
              <option v-for="company in companies" :key="company._id" :value="company._id">
                {{ company.companyName }}
              </option>
            </select>
          </td>
          <td>
            <button @click="activateUser(user._id)">Activate</button>
          </td>
        </tr>
      </tbody>
    </table>

    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import { useUserStore } from '../stores/userStore';

export default {
  setup() {
    const inactiveUsers = ref([]); // Users who are inactive
    const companies = ref([]);     // List of companies available for assignment
    const selectedCompany = ref({}); // Store the selected company for each user
    const message = ref('');       // Success or error message

    const apiUrl = inject('apiUrl'); // Inject the global apiUrl
    const userStore = useUserStore(); // Initialize Pinia user store

    const fetchInactiveUsers = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/admin/inactive-users`, {
          credentials: 'include',
        });
        if (response.ok) {
          inactiveUsers.value = await response.json();
          console.log('fetchInactiveUsers=', inactiveUsers.value);
        } else {
          console.error('Error fetching inactive users:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching inactive users:', error);
      }
    };

    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/companies`, {
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          companies.value = data.companies || data; // Adjust based on response structure
        } else {
          console.error('Error fetching companies:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    const activateUser = async (userId) => {
      const companyId = selectedCompany.value[userId];
      if (!companyId) {
        message.value = 'Please select a company to assign.';
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/api/admin/activate-user/${userId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ companyId }),
        });
        if (response.ok) {
          message.value = 'User activated successfully!';
          await fetchInactiveUsers(); // Refresh the list after activation
        } else {
          console.error('Error activating user:', response.statusText);
          message.value = 'Error activating user. Please try again.';
        }
      } catch (error) {
        console.error('Error activating user:', error);
        message.value = 'Error activating user. Please try again.';
      }
    };

    onMounted(async () => {
      await fetchInactiveUsers();
      await fetchCompanies();
    });

    return {
      inactiveUsers,
      companies,
      selectedCompany,
      message,
      activateUser,
    };
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

select {
  padding: 5px;
}
</style>
