<!-- frontend/src/components/AccountList.vue -->
<template>
  <div class="account-list">
    <!-- Header Section -->
    <div class="header">
      <h1>Accounts</h1>
      <button @click="toggleSearch" class="toggle-search-btn">
        {{ accountStore.showSearch ? 'Hide Search' : 'Show Search' }}
      </button>
    </div>

    <!-- Search Form -->
    <div v-if="accountStore.showSearch" class="search-form">
      <form @submit.prevent="applyFilters">
        <!-- Name Filter -->
        <div class="form-group">
          <label for="name">Name:</label>
          <input
            type="text"
            id="name"
            v-model="accountStore.filters.name"
            placeholder="Search by name"
          />
        </div>

        <!-- Nature of Business Filter -->
        <div class="form-group">
          <label for="natureOfBusiness">Nature of Business:</label>
          <input
            type="text"
            id="natureOfBusiness"
            v-model="accountStore.filters.natureOfBusiness"
            placeholder="Search by nature of business"
          />
        </div>

        <!-- GST Number Filter -->
        <div class="form-group">
          <label for="gstHstNumber">GST/HST Number:</label>
          <input
            type="text"
            id="gstHstNumber"
            v-model="accountStore.filters.gstHstNumber"
            placeholder="Search by GST/HST number"
          />
        </div>

        <!-- Address Filter -->
        <div class="form-group">
          <label for="address">Address:</label>
          <input
            type="text"
            id="address"
            v-model="accountStore.filters.address"
            placeholder="Search by address"
          />
        </div>

        <!-- Phone Number Filter -->
        <div class="form-group">
          <label for="phoneNumber">Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            v-model="accountStore.filters.phoneNumber"
            placeholder="Search by phone number"
          />
        </div>

        <!-- Active Status Filter -->
        <div class="form-group">
          <label for="activeStatus">Active Status:</label>
          <select id="activeStatus" v-model="accountStore.filters.activeStatus">
            <option value="">All</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>

        <!-- Filter Buttons -->
        <button type="submit" class="apply-filters-btn">Apply Filters</button>
        <button type="button" @click="resetFilters" class="reset-filters-btn">
          Reset Filters
        </button>
      </form>
    </div>

    <!-- Accounts Table -->
    <div class="accounts">
      <!-- New Account Button -->
      <button @click="navigateToNewAccount" class="new-account-btn">New Account</button>

      <!-- Loading Indicator -->
      <div v-if="accountStore.loading" class="loading">Loading accounts...</div>

      <!-- Accounts List -->
      <div v-else>
        <div v-if="accountStore.accounts.length">
          <table class="common-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Nature of Business</th>
                <th>GST Number</th>
                <th>Address</th>
                <th>Phone Numbers</th>
                <th>Active Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="account in accountStore.accounts"
                :key="account._id"
                @click="viewAccount(account._id)"
                class="account-row"
              >
                <td>{{ account.name }}</td>
                <td>{{ account.natureOfBusiness }}</td>
                <td>{{ account.gstHstNumber }}</td>
                <td>{{ formatAddress(account) }}</td>
                <td>
                  <div>
                    <div v-if="account.mobileNumber">
                      Mobile: {{ account.mobileNumber }}
                    </div>
                    <div v-if="account.officeNumber">
                      Office: {{ account.officeNumber }}
                    </div>
                  </div>
                </td>
                <td>{{ account.isActive ? 'Active' : 'Inactive' }}</td>
              </tr>
            </tbody>
          </table>

          <!-- Pagination Controls -->
          <div class="pagination">
            <button
              :disabled="accountStore.currentPage === 1"
              @click="changePage(accountStore.currentPage - 1)"
            >
              Previous
            </button>
            <span>Page {{ accountStore.currentPage }} of {{ accountStore.totalPages }}</span>
            <button
              :disabled="accountStore.currentPage === accountStore.totalPages"
              @click="changePage(accountStore.currentPage + 1)"
            >
              Next
            </button>
          </div>
        </div>

        <!-- No Results Message -->
        <div v-else class="no-results">
          No accounts found matching the criteria.
        </div>
      </div>

      <!-- Error Message -->
      <div v-if="accountStore.error" class="error">{{ accountStore.error }}</div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useAccountStore } from '../stores/accountStore';

export default {
  name: 'AccountList',
  setup() {
    const accountStore = useAccountStore();
    const router = useRouter();
    const apiUrl = import.meta.env.VITE_API_URL;

    // Fetch accounts when the component is mounted
    accountStore.fetchAccounts();

    // Toggle the visibility of the search form
    const toggleSearch = () => {
      accountStore.toggleSearch();
    };

    // Apply filters based on user input
    const applyFilters = () => {
      accountStore.setFilters(accountStore.filters);
    };

    // Reset all filters to their default values
    const resetFilters = () => {
      accountStore.resetFilters();
    };

    // Change the current page for pagination
    const changePage = (newPage) => {
      accountStore.changePage(newPage);
    };

    // Navigate to the AccountForm.vue in view mode
    const viewAccount = (accountId) => {
      router.push({ name: 'AccountForm', params: { id: accountId } });
    };

    // Navigate to the AccountForm.vue in insert mode
    const navigateToNewAccount = () => {
      router.push({ name: 'NewAccount', params: { id: undefined } });
    };

    // Helper function to format address
    const formatAddress = (account) => {
      const {
        addressLine1,
        addressLine2,
        city,
        province,
        postalCode,
        country,
      } = account;
      return [
        addressLine1,
        addressLine2,
        city,
        province,
        postalCode,
        country,
      ]
        .filter((part) => part)
        .join(', ');
    };

    return {
      accountStore,
      toggleSearch,
      applyFilters,
      resetFilters,
      changePage,
      viewAccount,
      navigateToNewAccount,
      formatAddress,
    };
  },
};
</script>

<style scoped>
.account-list {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-search-btn {
  padding: 8px 16px;
  cursor: pointer;
}

.search-form {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 8px;
  font-size: 1em;
}

.apply-filters-btn,
.reset-filters-btn {
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.new-account-btn {
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.edit-btn {
  padding: 6px 12px;
  cursor: not-allowed; /* Indicate that the button is disabled */
  opacity: 0.6; /* Visual cue for disabled state */
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  padding: 8px 12px;
  margin: 0 10px;
  cursor: pointer;
}

.loading,
.no-results,
.error {
  text-align: center;
  margin: 20px 0;
}

.error {
  color: red;
}

/* Remove table-specific styles since they are now handled by .common-table */
.accounts table {
  /* No styles needed here */
}

/* Remove the following styles */
.accounts th,
.accounts td {
  /* border: 1px solid #ddd;
  padding: 8px; */
}

.accounts th {
  /* background-color: #f2f2f2; */
}

.account-row:hover {
  /* background-color: #f9f9f9; */
}
</style>