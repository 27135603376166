// Import the functions you need from the SDKs you need
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBfuvb_ddA4f0XAGfh4zG-l_6BB5wfA5PQ",
  authDomain: "diten-auth.firebaseapp.com",
  projectId: "diten-auth",
  storageBucket: "diten-auth.appspot.com",
  messagingSenderId: "30789260410",
  appId: "1:30789260410:web:a6299624c9e465d00757c3",
  measurementId: "G-R74P5V4XY6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

// Ensure the auth state persists across page reloads
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Firebase Auth persistence set to local storage.");
  })
  .catch((error) => {
    console.error("Error setting Firebase Auth persistence:", error);
  });

export { auth }; 