<template>
    <div class="layout">
      <div class="main-content">
        <slot name="main"></slot>
      </div>
      <div class="related-content">
        <slot name="related"></slot>
      </div>
    </div>
  </template>
  
  <style scoped>
  .layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    height: 100vh; /* Full viewport height */
    padding: 20px;
    align-items: center; /* Center content vertically */
  }
  
  .main-content,
  .related-content {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
  }
  
  @media (min-width: 768px) {
    .layout {
      grid-template-columns: 60% 40%;
      align-items: start; /* Align content to the start vertically for larger screens */
    }
  
    .main-content {
      grid-column: 1 / 2;
    }
  
    .related-content {
      grid-column: 2 / 3;
    }
  }
  </style>
  