<template>
  <div class="tax-return-list">
    <div class="tabs">
      <button
        :class="{ active: activeTab === 'unfiled' }"
        @click="activeTab = 'unfiled'"
        :disabled="loading"
      >
        Unfiled Returns
      </button>
      <button
        :class="{ active: activeTab === 'filed' }"
        @click="activeTab = 'filed'"
        :disabled="loading"
      >
        Filed Returns
      </button>
    </div>

    <div v-if="loading">Loading Tax Returns...</div>
    <div v-else>
      <ul>
        <TaxReturnItem
          v-for="taxReturn in taxReturnsList"
          :key="taxReturn._id"
          :taxReturn="taxReturn"
          @click="viewTaxReturnDetail(taxReturn._id)"
        />
      </ul>
      <div v-if="taxReturnsList.length === 0">
        No Tax Returns to display.
      </div>
    </div>

    <div v-if="error">{{ error }}</div> <!-- Error handling -->
  </div>
</template>

<script>
import TaxReturnItem from './TaxReturnItem.vue';
import { ref, computed, onMounted, watch, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '../stores/userStore'; // Updated import

export default {
  components: { TaxReturnItem },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const activeTab = ref('unfiled');
    const taxReturns = ref([]);
    const loading = ref(false);
    const error = ref(null);

    const apiUrl = inject('apiUrl'); // Ensure the global API URL is injected
    const userStore = useUserStore(); // Initialize Pinia user store

    // Function to fetch tax returns
    const fetchTaxReturns = async () => {
      // Ensure companyId exists before making the API call
      const companyId = userStore.companyId;

      if (!companyId) {
        console.error('Company ID missing. Cannot fetch tax returns.');
        error.value = 'Company ID is missing. Please select a company.';
        return;
      }

      loading.value = true;
      error.value = null;

      try {
        console.log('Fetching tax returns for account ID:', props.accountId);
        const response = await fetch(
          `${apiUrl}/api/taxreturns/account/${props.accountId}?filed=${activeTab.value === 'filed' ? 'true' : 'false'}`,
          { credentials: 'include', });
        if (response.ok) {
          taxReturns.value = await response.json();
          console.log('TaxReturnList.Fetched Tax Returns:', taxReturns);
        } else {
          console.error('Error fetching Tax Returns:', response.statusText);
          error.value = 'Failed to load tax returns. Please try again.';
        }
      }catch (error) {
          console.error('Error fetching Tax Returns:', error);
      }finally {
        loading.value = false;
      };

    };

    // Fetch tax returns when the component is mounted, only if companyId is available
    onMounted(() => {
      console.log('TaxReturnList.onMounted() started.');
      if (userStore.user.companyId) {
        fetchTaxReturns();
      }else
        console.log('TaxReturnList: No companyId found in session.');
      // Watch for changes in companyId and trigger fetching when it changes
      watch(
        () => userStore.companyId,
        (newCompanyId) => {
          if (newCompanyId) {
            fetchTaxReturns(); // Fetch tax returns when companyId becomes available
          }
        }
      );
    });

    // Watch for changes in the active tab (unfiled/filed)
    watch(activeTab, () => {
      fetchTaxReturns();
    });

    const taxReturnsList = computed(() => taxReturns.value);

    const viewTaxReturnDetail = (id) => {
      router.push({ name: 'TaxReturnDetail', params: { id } });
    };

    return {
      activeTab,
      taxReturnsList,
      loading,
      error,
      viewTaxReturnDetail,
    };
  },
};
</script>

<style scoped>
.tax-return-list {
  /* Styles for your list */
}

.tabs {
  display: flex;
}

.tabs button {
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: #eee;
}

.tabs button.active {
  background-color: #ccc;
}

.tabs button:disabled {
  cursor: not-allowed;
}
</style>
