<!-- src/components/TaxReturnItem.vue -->
<template>
    <li class="tax-return-item" @click="$emit('click')">
      <div>
        <strong>{{ taxReturn.returnType }}</strong> - {{ taxReturn.taxYear }}
      </div>
      <div>
        Period: {{ formattedPeriod }} | Due: {{ formattedDueDate }}
      </div>
      <div>Status: {{ taxReturn.status }}</div>
    </li>
  </template>
  
  <script>
  import { computed } from 'vue';
  
  export default {
    props: {
      taxReturn: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const formattedPeriod = computed(() => {
        const start = new Date(props.taxReturn.periodStartDate).toLocaleDateString();
        const end = new Date(props.taxReturn.periodEndDate).toLocaleDateString();
        return `${start} - ${end}`;
      });
  
      const formattedDueDate = computed(() => {
        return new Date(props.taxReturn.dueDate).toLocaleDateString();
      });
  
      return {
        formattedPeriod,
        formattedDueDate,
      };
    },
  };
  </script>
  
  <style scoped>
  .tax-return-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .tax-return-item:hover {
    background-color: #f9f9f9;
  }
  </style>
  