<template>
  <div>
    <h2>Sign Up</h2>
    <form @submit.prevent="signUp">
      <input type="email" v-model="email" placeholder="Email" required />
      <input type="password" v-model="password" placeholder="Password" required />
      <button type="submit">Sign Up</button>
    </form>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '../stores/userStore'; // Import the Pinia store

export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const error = ref(null);
    const apiUrl = inject('apiUrl'); // Inject the global apiUrl
    const router = useRouter();
    const userStore = useUserStore(); // Initialize Pinia user store

    const signUp = async () => {
      error.value = null; // Clear previous error messages

      try {
        const response = await fetch(`${apiUrl}/api/auth/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include cookies for session management
          body: JSON.stringify({
            email: email.value,
            password: password.value,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          userStore.setUser(data.user); // Update the user in Pinia store
          router.push('/'); // Redirect to the home page or desired route
        } else {
          const errorData = await response.json();
          error.value = errorData.message || 'Error signing up. Please try again.';
        }
      } catch (err) {
        console.error('Error during sign up:', err);
        error.value = 'An unexpected error occurred. Please try again later.';
      }
    };

    return {
      email,
      password,
      error,
      signUp,
    };
  },
};
</script>

<style scoped>
/* Add any relevant styles here */
</style>
