<template>
  <header>
    <nav>
      <router-link :to="{ name: 'Home' }" :class="{ active: isActive('/') }">Home</router-link>

      <!-- Company Context Dropdown for System Admin -->
      <div v-if="userProfile === 'System Admin'">
        <label for="companyContext">Company Context:</label>
        <!-- Show Loading text while companies are being fetched -->
        <div v-if="loading">Loading companies...</div>
        <!-- Show the dropdown once companies are loaded -->
        <select v-else id="companyContext" v-model="contextCompany" @change="updateContextCompany">
          <option v-for="company in companies" :key="company._id" :value="company._id">
            {{ company.companyName }} (ID: {{ company._id }})
          </option>
          <option value="">-- Unselect Company --</option>
        </select>
      </div>

      <!-- Navigation Links -->
      <li v-if="userProfile === 'System Admin'" :class="{ active: isActive('/companies') }">
        <router-link to="/companies">Companies</router-link>
      </li>
      <li v-if="userProfile === 'System Admin'" :class="{ active: isActive('/admin') }">
        <router-link to="/admin">Admin Dashboard</router-link>
      </li>
      <li :class="{ active: isActive('/accounts') }">
        <router-link to="/accounts">Accounts</router-link>
      </li>
      <!--li :class="{ active: isActive('/files') }">
        <router-link to="/files">Files</router-link>
      </li-->

      <!-- Sign In/Out Links >
      <router-link v-if="!isAuthenticated" to="/signin">Sign In</router-link>
      <router-link v-if="!isAuthenticated" to="/signup">Sign Up</router-link-->
      <div v-if="isAuthenticated">
        <span>Welcome, {{ user.email }}</span>
        <button @click="logout">Logout</button>
      </div>
    </nav>
  </header>
</template>

<script>
import { ref, onMounted, computed, inject, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '../stores/userStore'; // Updated import
import { auth } from '../firebase';

export default {
  name: 'Header',
  setup() {
    const userStore = useUserStore(); // Initialize Pinia store
    const contextCompany = ref(userStore.companyId); // Initialize with store's companyId
    const companies = ref([]); // Store the list of companies
    const loading = ref(false); // Add loading state
    const router = useRouter();
    const route = useRoute();

    const apiUrl = inject('apiUrl'); // Inject the globally provided apiUrl

    // Function to fetch companies for System Admin
    const fetchCompanies = async () => {
      loading.value = true; // Start loading here
      try {
        const response = await fetch(`${apiUrl}/api/companies`, { credentials: 'include' });
        if (response.ok) {
          companies.value = await response.json();
          console.log('Fetched companies:', companies.value);
        } else {
          console.error('Failed to fetch companies:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        loading.value = false; // Stop loading
      }
    };

    // Function to fetch user session details
    const fetchSession = async () => {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await fetch(`${apiUrl}/api/auth/session`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          credentials: 'include',
        });
        const sessionData = await response.json();
        userStore.setUser(sessionData.user); // Use Pinia action to set user
        contextCompany.value = userStore.companyId; // Update contextCompany
        console.log('User profile:', userStore.user?.profile);
      } catch (error) {
        console.error('Error fetching session in Header:', error);
        userStore.clearUser(); // Use Pinia action to clear user
        router.push('/signin');
      }
    };

    // Update context company
    const updateContextCompany = async () => {
      try {
        console.log('Header: ContextCompany update: Company selected:', contextCompany.value);
        // Save context-company in session
        await fetch(`${apiUrl}/api/auth/session/context-company`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ contextCompany: contextCompany.value }),
        });

        // Update the companyId in the Pinia store
        userStore.updateCompanyId(contextCompany.value);

        // Optionally fetch the updated session to confirm
        // const response = await fetch(`${apiUrl}/api/auth/session`, {
        //   credentials: 'include',
        // });
        // const data = await response.json();
        // console.log('Updated session after selecting company:', data);
      } catch (error) {
        console.error('Error updating context company:', error);
      }
    };

    // Fetch companies and session data on component mount
    onMounted(async () => {
      if (!userStore.isAuthenticated) {
        await fetchSession();
      }

      if (userProfile.value === 'System Admin') {
        await fetchCompanies();
      }
    });

    // Watch for changes in user profile
    watch(
      () => userStore.user?.profile,
      async (newProfile) => {
        if (newProfile === 'System Admin') {
          await fetchCompanies();
        }
      }
    );

    // Access the user data from userStore
    const user = computed(() => {
      console.log('Header: User state:', userStore.user);
      return userStore.user;
    });

    const userProfile = computed(() => {
      console.log('User profile:', userStore.userProfile);
      return userStore.userProfile;
    });

    const isAuthenticated = computed(() => userStore.isAuthenticated);

    const logout = async () => {
      try {
        // Sign out from Firebase
        await auth.signOut();

        // Clear user data in userStore
        userStore.clearUser();
        contextCompany.value = null;

        // Sign out from the backend session
        await fetch(`${apiUrl}/api/auth/logout`, {
          method: 'POST',
          credentials: 'include',
        });

        router.push('/signin');
        console.log('User signed out');
      } catch (error) {
        console.error('Sign out error:', error);
      }
    };

    const isActive = (path) => route.path === path;

    return {
      user,
      userProfile,
      isAuthenticated,
      contextCompany,
      companies,
      loading,
      logout,
      isActive,
      updateContextCompany,
    };
  },
};
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

nav {
  display: flex;
  align-items: center;
}

nav a {
  margin-right: 1em;
  text-decoration: none;
  color: #007bff;
}

nav a:hover {
  text-decoration: underline;
}

button {
  border: none;
  background: none;
  color: #007bff;
  cursor: pointer;
}

button:hover {
  text-decoration: underline;
}

.active {
  font-weight: bold;
}
</style>
